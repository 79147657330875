$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

@mixin device-max($breakpoint) {
  @each $name, $pixels in $grid-breakpoints {
    @if $breakpoint == $name {
      @media only screen and (max-width: $pixels) {
        @content;
      }
    }
  }
}

@mixin device-min($breakpoint) {
  @each $name, $pixels in $grid-breakpoints {
    @if $breakpoint == $name {
      @media only screen and (min-width: $pixels) {
        @content;
      }
    }
  }
}
