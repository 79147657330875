.cardCarousel {
  min-width: 300px;
  display: flex;
  justify-content: flex-start;
  width: auto;
  padding: 20px 10px;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-snap-type: both mandatory;
  overscroll-behavior-x: contain;
  &::-webkit-scrollbar{
    display: none;
  }
}
