@import "@styles/articles.scss";
@import "@sepstyles/textmixins.scss";

$text-color: var(--color-text);
$primary: var(--color-primary);
$button-color: var(--color-secondary);

.wrapper {
  overflow-x: hidden;
  @media screen and (min-width: 768px) {
    padding: 0;
  }

  @media screen and (min-width: 1440px) {
    display: block;
    margin: 60px auto 0 auto;
  }
}

.userDashboard {
  .leftCol {
    margin-top: 0;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding: 0 40px;
    min-height: 500px;

    .leftCol {
      flex: 0.5;
      margin-top: 40px;
      margin-right: 40px;

      .greetings {
        font-size: 39px;
      }

      .chat {
        width: 100%;
        box-shadow: 0px 4px 8px 1px rgba(157, 156, 181, 0.3);
        border-radius: 20px;

        .cardBody {
          padding: 35px 25px;

          p {
            font-size: 16px;
          }
        }

        .footer {
          padding: 15px 0;
          color: white;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .leftCol {
      .chat {
        width: 90%;
        p {
          width: 60%;
        }
      }
    }
  }

  @media screen and (min-width: 1280px) {
    .leftCol {
      .chat {
        width: 80%;
      }
    }
  }
}

.cardLink {
  text-decoration: none !important;
  border-radius: 20px;
  .chatCard {
    box-shadow: none;
    background-color: var(--color-white);
    border: none;
    border-radius: 20px;
    p {
      font-weight: 200;
      font-size: 14px;
      line-height: 17px;
      color: $text-color;
    }
    .footer {
      background-color: var(--color-secondary);
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      color: white;
      a {
        font-weight: 510;
        font-size: 14px;
        line-height: 17px;
        color: var(--color-white) !important;
      }
    }
  }
}

.greetings {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: $text-color;
  position: relative;
  display: inline-block;
}

.membershipDashboardWrapper {
  @media screen and (min-width: 768px) {
    flex: 0.5;
  }
}

.membershipDashboard {
  border: none;
  padding-bottom: 10px;
  .cardHeader {
    background-color: transparent;
    border: none;
    padding-left: 0;

    p {
      span {
        font-weight: 600;

        &.gradient {
          @include textGradient;
        }
      }
    }
  }
  .cardBody {
    background: var(--color-gray);
    box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3);
    border-radius: 10px;

    .mobileChatCard {
      margin-top: 0 !important;
    }

    .header {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $text-color;
    }
    .info {
      background-color: var(--color-white);
      border-radius: 10px;
      padding: 10px 13px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: nowrap;
      span {
        display: inline-block;
      }
      .label {
        width: 45%;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;

        svg {
          transform: scale(0.6);

          path {
            stroke: $button-color;
          }
        }
      }
      .divider {
        width: 10%;
        text-align: center;
        font-size: 20px;
      }
      .value {
        width: 45%;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .link {
      font-size: 14px;
      line-height: 17px;
      color: var(--color-secondary);

      svg {
        margin-left: 4px;
      }
    }
    .button {
      padding: 12px 9px;
    }
  }

  @media screen and (min-width: 768px) {
    .cardBody {
      .header {
        text-align: center;
        font-size: 20px;
        margin-bottom: 15px;
      }

      .link {
        display: block;
        text-align: center;
      }
    }
  }
  .description {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-premium-gray);
  }
}

.modal {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal p {
  font-size: 16px;
}

.horizontalLine{
  height: 2px;
  background-color: #FFF;
  margin-top: 24px;
}

.negativeMarginContainer{
  margin-top: -16px;
  @media screen and (min-width: 768px) {
    margin-top: 50px;
    .headerWrapper {
      h2 {
        text-align: center;
        flex: 1;
        padding: 15px;
        border-top: 1px solid var(--color-border-dark);
        border-bottom: 1px solid var(--color-border-dark);
        color: var(--color-text);
        font-size: 22px;
      }
      a {
        display: none;
      }
    }
  }
}
