$color_primary:#007e84;
$color_primary_light: #03C8B0;
$color_secondary: #59A9AE;
$color_secondary_light: #D5FFEF;
$color_white:#FFFFFF;
$background_primary:#FFFFFF;
$background_secondary:#F8F8F8;
$background_tertiary:#D5E3ED;
$body_neutral:#535353;
$body_primary:#102B3F;
$body_secondary:#2A4960;
$body_tertiary:#6E91B1;
$body_primary_light: #D1D3D4;
$header_neutral:#002F35;
$inactive_neutral: #A7CED1;
$highlight_primary: #C4F4EE;
$color_gradient_light: #00D984;
