$text-color: var(--color-text);
$primary: var(--color-primary);
$button-color: var(--color-secondary);

.summaryCard {
    .header {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $text-color;
    }
    .info {
      background-color: var(--color-white);
      border-radius: 10px;
      padding: 10px 13px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: nowrap;
      span {
        display: inline-block;
      }
      .label {
        width: 45%;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;

        svg {
          transform: scale(0.6);

          path {
            stroke: $button-color;
          }
        }
      }
      .divider {
        width: 10%;
        text-align: center;
        font-size: 20px;
      }
      .value {
        width: 45%;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .link {
      font-size: 14px;
      line-height: 17px;
      color: var(--color-secondary);

      &+svg {
        margin-left: 4px;
        color: var(--color-secondary);
      }
    }
    .button {
      padding: 12px 9px;
    }
  }

  .description {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-premium-gray)
  }

.horizontalLine{
  height: 2px;
  background-color: #FFF;
  margin-top: 24px;
}
