@mixin card-item-content() {
  background-color: #FFF;
  span {
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
  }
  h3 {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.32px;
  }
  .iconButton {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: $color_white;
    svg {
      margin-left: -2px;
    }
  }
}
