@import '@v4/styles/variables.scss';

.disclaimer {
  h5 {
    color: $header_neutral;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.28px;
  }
  .list {
    background: transparent;
    .item {
      background-color: inherit;
      border: none;
      color: $body_neutral;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.28px;
    }
  }
  a {
    color: $color_primary;
  }
}
