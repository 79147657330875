@keyframes slideRight {
  0% {
    transform: scaleX(0);
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}

@mixin textWithAnimatedBackground {
  position: relative;
  display: inline-block;
  font-size: 30px;
  font-weight: 600;
  color: var(--color-text);
  z-index: 1;
}

@mixin textGradient {
  background: linear-gradient(90deg, var(--color-primary) 2.08%, var(--color-secondary) 88.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
