.accordionItem {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #D5E3ED;;
  &:global(.accordion-item) {
    &:last-of-type {
      border-radius: 0;
      border: none;
    }
  }
}

.accordionHeader {
  :global(.accordion-button) {
    background-color: transparent;
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    padding-left: 0;
    &:not(.collapsed) {
      color: inherit;
      background-color: inherit;
      box-shadow: none;
    }
    &:focus {
      border: none;
      box-shadow: none;
    }
    &::after {
      background-color: #A7CED1;
      padding: 15px;
      border-radius: 50%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M14.9998 10.8332H10.8332V14.9998C10.8332 15.4582 10.4582 15.8332 9.99984 15.8332C9.5415 15.8332 9.1665 15.4582 9.1665 14.9998V10.8332H4.99984C4.5415 10.8332 4.1665 10.4582 4.1665 9.99984C4.1665 9.5415 4.5415 9.1665 4.99984 9.1665H9.1665V4.99984C9.1665 4.5415 9.5415 4.1665 9.99984 4.1665C10.4582 4.1665 10.8332 4.5415 10.8332 4.99984V9.1665H14.9998C15.4582 9.1665 15.8332 9.5415 15.8332 9.99984C15.8332 10.4582 15.4582 10.8332 14.9998 10.8332Z' fill='black'/%3E%3C/svg%3E");
      background-position: center;
    }
    &:global(:not(.collapsed)::after) {
      transform: rotate(135deg);
    }
  }
}

.accordionBody {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  padding-left: 0;
  padding-top: 0;
}
