@import '@separate/v4/styles/responsive/devicesMixins';

.card {
  border-radius: 16px;
  border: none;
  position: relative;
  img {
    border-radius: inherit;
    object-fit: cover;
    object-position: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
  }
  .badge {
    display: inline-block;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.28px;
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 8px 12px;
  }
}

span {
  &.date {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.32px;
    @include device-max("xl") {
      font-size: 14px;
    }
  }
}

.title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  @include device-max("xl") {
    font-size: 20px;
    line-height: 28px;
  }
}

p.text {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  @include device-max("xl") {
    font-size: 16px;
    line-height: 24px;
  }
}

.link {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  position: relative;
  display: inline-flex;
  align-items: center;
  color: #000;
  background-color: transparent;
  border: none;
  &:hover, &:active {
    background-color: inherit !important;
    color: inherit;
  }
  @include device-max("xl") {
    font-size: 16px;
  }
}
