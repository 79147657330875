.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.reverseOrder {
  flex-direction: column-reverse;
}

.rowWrapper {
  .sectionHeader {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: var(--color-text);
    margin: 0;
  }

  .viewMore {
    font-size: 16px;
  }

  .viewMore:after {
    content: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M1 11L6 6L1 1' stroke='%23007E84' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E");
    position: relative;
    left: 4px;
    top: 1px;
  }

  .headerSubtitle {
    font-size: 16px;
    color: var(--color-premium-gray);
  }
}

.rowWrapperDesktop {
  @media screen and (min-width: 768px) {
    margin-top: 50px;
    padding: 0 40px;
    .sectionHeader {
      text-align: center;
      flex: 1;
      padding: 15px;
      border-top: 1px solid var(--color-border-dark);
      border-bottom: 1px solid var(--color-border-dark);
      color: var(--color-text);
      font-size: 22px;
      margin-bottom: 24px;
    }

    .viewMore, .headerSubtitle {
      display: none;
    }
  }

  @media screen and (min-width: 1280px) {
    padding: 0 100px;
  }
}

.spinner {
  color: var(--color-secondary);
}
