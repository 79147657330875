@import '@v4/styles/variables.scss';

.article {
  background-color: transparent;
  :global([class*="ArticleCard_date"]) {
    color: $body_tertiary;
  }
  :global([class*="ArticleCard_title"]) {
    color: $color_primary;
  }
  :global([class*="ArticleCard_text"]) {
    color: $body_neutral;
  }
  :global([class*="ArticleCard_link"]) {
    color: $color_primary;
  }
  :global([class*="ArticleCard_badge"]) {
    background-color: $background_tertiary !important;
    color: $color_primary;
  }
}
