.cardLink {
  display: block;
  text-decoration: none !important;

  &:not(:first-child) {
    margin-top: 25px !important;
  }
}

.card {
  background-color: white;
  border: 0;
  border-radius: 20px;
  box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.15);

  :hover {
    cursor: pointer;
  }

  .top {
    position: relative;
    padding: 1rem;
    border-bottom: 1px solid var(--color-gray);

    img {
      width: 64px;
      height: 52px;
    }

    .topHeader {
      .icon {
        width: 14px;
        height: 14px;
      }

      span {
        margin: 0 0 0 5px;
        padding: 0;
        font-size: 12px;
        background-image: linear-gradient(90.56deg, var(--color-primary) 0.48%, var(--color-secondary) 99.65%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    h6 {
      font-size: 16px;
      font-weight: 500;
      width: 95%;
      color: var(--color-text);
    }

    .chevronRight {
      color: var(--color-secondary);
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translate(0%, -50%);
    }
  }

  .bottom {
    .column {
      flex: 0.5;
      padding: 1rem 1rem 1.5rem 1rem;

      &:nth-child(2) {
        border-left: 1px solid var(--color-gray);
        .iconWrapper {
          .icon {
            transform: scale(1);
          }
        }
      }

      .iconWrapper {
        height: 36px;
        width: 36px;
        border-radius: 50%;
        background-color: var(--color-gray);
        display: grid;
        place-items: center;
        .icon {
          color: var(--color-secondary);
          transform: scale(0.6);
        }
      }

      .columnContent {
        margin-left: 15px;
        span {
          font-size: 10px;
          color: var(--color-premium-gray);
        }
        p {
          font-size: 14px;
          font-weight: 600;
          margin: 0;
          color: var(--color-text);
        }
      }
    }
  }
}
