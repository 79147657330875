@import '@separate/v4/styles/responsive/devicesMixins';
@import '@separate/v4/styles/cardCarousel/contentMixins';
@import '@v4/styles/variables.scss';

@mixin card-effects() {
  background-color: $color_primary;
  box-shadow: rgba(0, 126, 133, 0.2) 0px 3px 6px, rgba(0, 126, 133, 0.8) 0px 3px 6px;
  span, h3, p, .icon {
    color: $color_white;
  }
  .iconButton {
    background-color: $color_white;
    border-color: $color_white;
    color: $color_primary;
  }
}

.cardCarousel {
  a {
    text-decoration: none;
    &:hover {
      .cardItem {
        @include card-effects();
      }
    }
    :global(.isInViewport) {
      @include device-max("xl") {
        .cardItem {
          @include card-effects();
        }
      }
    }
    .active {
      @include device-min("xl") {
        .cardItem {
          @include card-effects();
        }
      }
    }
  }

  .cardItem {
    @include card-item-content();
    transition: all 0.4s ease-in-out;
    background-color: $color_white;
    border-color: $color_primary;
    @include device-min('md') {
      margin-right: 64px;
    }

    span {
      color: $color_primary;
    }
    h3 {
      color: $header_neutral;
    }
    p {
      color: $body_neutral;
    }
    .icon {
      color: $color_primary;
    }
    .iconButton {
      background-color: $color_primary;
      color: $color_white;
      border-color: $color_primary;
    }
  }
}

.popular {
  background-color: $background_tertiary;
  width: fit-content;
  padding: 8px 12px;
  border-radius: 8px;
  margin: 20px auto;
  margin-left: 70px;

  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--color-secondary);
  }
}
