@import '@separate/v4/styles/buttons/buttonMixins';
@import '@separate/v4/styles/headings/horizontalGradientMixins';
@import '@separate/v4/styles/responsive/devicesMixins';
@import '@v4/styles/variables.scss';

@mixin background-highlight($color) {
  margin-right: 12%;
  &::after {
    content: "";
    background-color: $color;
    width: 70%;
    height: 125%;
    border-radius: inherit;
    position: absolute;
    top: -12%;
    right: -12%;
    z-index: 0;
  }
}

.home {
  .button {
    @include button();
    background-color: $color_primary;
    &:active {
      background-color: $color_primary;
      border-color:  $color_primary;
    }
    color: $color_white;
    width: 280px;

    @include device-max("md") {
      width: 100%;
    }
  }

  p {
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    color: $body_neutral;
    @include device-max("xl") {
      font-size: 18px;
      line-height: 24px;
    }
  }

  span {
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    color: $body_secondary;
    @include device-max("xl") {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .heading {
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px;
    color: $header_neutral;
    @include device-max("xl") {
      font-size: 44px;
      line-height: 56px;
    }
  }

  .headingAnimated {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    .heading {
      @include horizontal-gradient($color_gradient_light, $color_primary);
      transition: all 0.4s ease-in-out;
      opacity: 0;
      &:not(:nth-child(1)){
        position: absolute;
        top: 0;
      }
      &.active {
        opacity: 1;
      }
    }
  }

  h3 {
    display: inline-block;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    position: relative;
    padding-bottom: 10px;
    @include device-max("xl") {
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
    }
    &.underlinedHeading {
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: $color_primary_light;
        left: 0;
        bottom: 0;
        border-radius: 10px;
        @include device-max("xl") {
          width: 40%;
        }
      }
    }
  }

  .ratings {
    width: 140px;
    height: 28px;
  }

  .imageContainer {
    float: right;
    max-width: 540px;
    max-height: 450px;
    border-radius: 30px;
    position: relative;
    img {
      width: 100%;
      height: auto;
      max-height: 420px;
      border-radius: inherit;
      object-fit: cover;
      object-position: center;
      margin: auto;
      position: relative;
      z-index: 1;
    }
    &.animated {
      img {
        opacity: 0;
        transform: translateX(-20px);
        transition: all 0.4s ease-in-out;
      }
      &:global(.isInViewport) {
        img {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    @include background-highlight($highlight_primary);
    @include device-max("xl") {
      transform: scale(0.8);
    }
  }

  .bullet {
    color: $body_primary;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.32px;
    display: block;

    span {
      margin-left: 35px;
      font-weight: 400;
      font-size: 16px;
      display: block;

      @include device-max("md") {
        margin-left: 5px;
      }
    }
    &::before {
      content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z' fill='%23A7CED1'/%3E%3Crect width='16' height='16' transform='translate(4 4)' fill='%23A7CED1'/%3E%3Cpath d='M6 12.6157L10.0874 16.7031L18 7' stroke='%23007B82' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .main {
    @include device-min("xl") {
      min-height: 40vh;
    }
    .imageContainer {
      @include background-highlight($highlight_primary);
    }
  }

  .services {
    min-height: 30vh;
    background: linear-gradient(180deg, #FFF 0%, #EEFFFD 100%);
    h3 {
      margin: 0 auto;
      &::after {
        width: 70%;
        @include device-max("sm") {
          right: 0;
          margin: 0 auto;
        }
      }
    }
  }

  .health {
    @include device-min("xl") {
      min-height: 40vh;
    }
    .imageContainer {
      @include background-highlight($color_secondary_light);
    }
  }

  .rewards {
    background-color: $background_secondary;
    @include device-min("xl") {
      min-height: 40vh;
    }
    .imageContainer {
      @include background-highlight($color_secondary_light);
      float: left;
    }
  }

  .banking {
    @include device-min("xl") {
      min-height: 40vh;
    }
    .imageContainer {
      @include background-highlight($color_secondary_light);
    }
  }

  .advisor {
    background-color: $background_secondary;
    &.backgroundWhite {
      background-color: $color_white;
    }
    @include device-min("xl") {
      min-height: 40vh;
    }
    .imageContainer {
      @include background-highlight($color_secondary_light);
      &.floatLeft {
        float: left;
      }
    }
  }

  .articles {
    h3 {
      &.underlined {
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: $color_primary_light;
          left: 0;
          bottom: 0;
          border-radius: 10px;
          @include device-max("xl") {
            width: 75%;
          }
        }
      }
    }
  }

  .testimonials {
    .button {
      border-radius: 28px;
      background: linear-gradient(90deg, $color_primary_light 0%, $color_primary 100%);
    }
  }

  .disclaimer {
    background-color: $background_secondary;
    a {
      color: $color_primary;
      font-weight: bold;
    }
  }
}
