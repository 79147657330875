.card {
  background-color: #CCCC;
  border-radius: 40px;
  border: 2px solid;
  margin-right: 20px;
  flex-shrink: 0;
  scroll-snap-align: center;
  overflow: hidden;
  .cardBody {
    min-height: 250px;
    .placeholder {
      min-height: inherit;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
