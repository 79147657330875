.article-card.card {
  background: var(--color-white);
  border-radius: 12px;
  min-height: 200px;
  box-shadow: 0px 1px 1px 0px #b2b2b2;
  border: 0;
  position: relative;
  span {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.07px;
    color: var(--color-primary-dark);
    font-weight: bold;
    &.date {
      font-weight: normal;
      font-size: 9px;
      line-height: 11px;
      color: #999999;
      letter-spacing: -0.265294px;
    }
  }
  color: var(--color-body);
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.35px;
  .card-img,
  .card-img-bottom {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    object-fit: cover;
  }
  .card-body {
    padding: 10px 14px;
  }
  .title {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.35px;
    color: var(--color-body);
  }
  .truncate {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
  .icon-badge {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}

.legal {
  max-width: 960px;
  h1 {
    font-size: 21px !important;
    line-height: 25px;
    letter-spacing: 0.35px;
    color: var(--color-body) !important;
  }
  h2 {
    font-size: 18px !important;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.35px;
    color: var(--color-primary) !important;
  }
  h3 {
    font-size: 16px !important;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: var(--color-body) !important;
  }
  ol {
    list-style-type: upper-roman;
    ol {
      list-style-type: decimal;
      ol {
        list-style-type: upper-alpha;
      }
    }
  }
}
