@import "@styles/buttonmixins";
$card-bg-color: var(--color-secondary);
$button-color: var(--color-secondary);
$text-color: var(--color-white);

.ctacard {
  background: $card-bg-color;
  color: $text-color;
  border-radius: 20px;
  min-height: 280px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3);
  flex: 1;
  .backgroundImage {
    border-radius: 20px;
    background-position: 95% 25%;
    background-repeat: no-repeat;
    background-size: 65%;
    min-height: inherit;
    @media screen and (min-width: 768px) {
      background-size: 75%;
    }
  }
  .content {
    width: 75%;
    position: relative;
    flex: 1;
    border-radius: inherit;
    background-color: rgba(0, 124, 130, 0.6);
    @media screen and (min-width: 768px) {
      width: 100%;
      height: 100%;
    }
    hr {
      height: 2px;
      background-color: var(--color-white);
      opacity: 1;
      width: 52px;
    }
    h3 {
      font-size: 25px;
      line-height: 30px;
    }
    p {
      font-size: 16px;
      line-height: 19px;
    }
    .button {
      @include button-hover-theme($button-color, light);
      font-size: 14px;
      line-height: 17px;
      padding: 9px 26px;
      &:hover {
        border: 2px solid var(--color-white) !important;
      }
    }
  }
}
