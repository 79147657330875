@mixin button() {
  padding: 13px 32px;
  font-style: normal;
  font-weight: 500;
  border-radius: 28px;
  border: none;
  &:active {
    background-color: none;
  }
  &:not(:not(a)) {
    text-decoration: none;
    display: inline-block;
    text-align: center;
  }
  &:disabled {
    opacity: 0.4 !important;
  }
}
